import cls from 'clsx';
import React from 'react';
import { A } from '@/components/atoms/A/A';
import { IconAppStore } from '@/components/Icons/IconAppStore';
import { IconGooglePlay } from '@/components/Icons/IconGooglePlay';
import styles from './AppStoreLinks.module.scss';

export interface AppStoreLinksProps {
  appStoreTitle: string;
  appStoreUrl: string | undefined;
  className?: string;
  googlePlayTitle: string;
  googlePlayUrl: string | undefined;
}

export const AppStoreLinks = ({
  googlePlayTitle,
  googlePlayUrl,
  appStoreUrl,
  appStoreTitle,
  className,
}: AppStoreLinksProps) => (
  <div className={cls(styles.links, className)}>
    {appStoreUrl && (
      <A href={appStoreUrl} aria-label={appStoreTitle}>
        <IconAppStore />
      </A>
    )}
    {googlePlayUrl && (
      <A href={googlePlayUrl} aria-label={googlePlayTitle}>
        <IconGooglePlay />
      </A>
    )}
  </div>
);
